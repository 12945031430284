/* eslint-disable no-extra-boolean-cast */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-nested-ternary, react/no-danger, react/no-array-index-key, import/prefer-default-export */
import { Box, Flex, Text } from "@engaging-tech/components"
import DOMPurify from "dompurify"
import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"

import { getLabelData } from "../../lib/utils"
import CircularScore from "./circular-score"

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  overflow-x: hidden;

  padding: 16px;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
`

const ActionPlanStepTitle = styled.h1`
  width: 100%;

  font-size: 42px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 4px;

  color: #173c5a;
`

const ActionPlanStepWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ isEmpty }) => (isEmpty ? "0px" : "50px")};
  flex-direction: ${({ questionIndex }) => (questionIndex === 0 ? "column-reverse" : "column")};
  justify-content: space-around;

  @media only screen and (min-width: 1440px) {
    flex-direction: row;
  }
`

const ActionPlanStepContainer = styled.div`
  width: 100%;

  @media only screen and (min-width: 1280px) {
    max-width: 658px;
  }
`

const ActionPlanInnerStepContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

const ActionPlanTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;

  color: #000000cc;

  margin-bottom: 0;

  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 500px;
`
const ActionPlanScoreText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;
`
const ActionPlanScoreSpan = styled.span`
  font-size: 14px;
  font-weight: 700;

  color: ${({ color }) => color};
`
const ActionPlanFixedText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;

  margin-top: 16px;

  word-wrap: break-word;
  white-space: pre-wrap;
`

const OrderedList = styled.ol`
  list-style-type: decimal;

  padding-left: 16px;
  margin-top: 0;

  width: fit-content;
`

const OrderedListItem = styled.li`
  margin-top: 16px;
`

const UnorderedList = styled.ul`
  list-style-type: lower-alpha;
`

const SuggestedActionsContainer = styled.div`
  background-color: #f0f8ff;

  display: flex;
  flex-direction: column;

  margin-top: 15px;

  width: 100%;

  padding: 16px 20px;

  border-radius: 4px;
`

const SuggestedActionsTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  color: #00000099;
`

const SuggestedPhrasesContainer = styled.div`
  background-color: #e4fdf0;

  margin-top: 28px;

  display: flex;
  flex-direction: column;

  padding: 16px 20px;

  border-radius: 4px;
`

const ScoreContainerWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  margin-top: 24px;

  @media only screen and (min-width: 1280px) {
    margin-top: 0;
  }
`

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 340px;

  @media only screen and (min-width: 1280px) {
    margin-left: 30px;
  }
`

const ResourcesContainerWrapper = styled.div`
  margin-top: 20px;
  display: block;
`

const ResourcesContainer = styled(Box)`
  background-color: #dfe8e9;

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;

  padding: 20px 22px;
`

const ResourcesContainerTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  color: #00000099;
`

const containsText = text => (text ? !!text.replace(/<\/?[^>]+(>|$)/g, "") : false)

const ActionPlanBody = ({ question, questionIndex, preview }) => {
  const resourcesRef = useRef(null)

  useLayoutEffect(() => {
    const questionWrapper = document.getElementById(
      `${question?.sixStepsQuestionId}-${questionIndex}-action-plan-step-container`
    )

    const container = resourcesRef.current
    if (container) {
      container.style.maxHeight = `${questionWrapper.clientHeight}px`
    }
  }, [question?.sixStepsQuestionId, questionIndex])

  const isEmpty =
    !containsText(question.body.description) &&
    !containsText(question.body.suggestedActions) &&
    !containsText(question.body.suggestedPhrases) &&
    !question.body.resources?.length &&
    !question.body.questions?.length

  console.log(question.body.questions?.length)
  return (
    <ActionPlanStepWrapper key={questionIndex} questionIndex={questionIndex} isEmpty={isEmpty}>
      <ActionPlanStepContainer>
        <ActionPlanInnerStepContainer
          id={`${question?.sixStepsQuestionId}-${questionIndex}-action-plan-step-container`}
        >
          <Flex width={1 / 1} justifyContent="space-between" alignItems="start" my={1}>
            <ActionPlanTitle>{question.title}</ActionPlanTitle>
            <ActionPlanScoreText>
              Your score:{" "}
              <ActionPlanScoreSpan color={preview ? "#00000099" : getLabelData(question.score).color}>
                {preview || !question?.score || question.score === -1 ? "NA" : `${question.score}%`}
              </ActionPlanScoreSpan>
            </ActionPlanScoreText>
          </Flex>
          {containsText(question.body.description) && (
            <ActionPlanFixedText
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.body.description)
              }}
            />
          )}
          {question.body.questions?.length ? (
            <ActionStepPreviewQuestions questions={question.body.questions} />
          ) : null}
          <div>
            {containsText(question.body.suggestedActions) && (
              <SuggestedActionsContainer>
                <SuggestedActionsTitle>Suggested Actions:</SuggestedActionsTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(question.body.suggestedActions)
                  }}
                />
              </SuggestedActionsContainer>
            )}
            {containsText(question.body.suggestedPhrases) && (
              <SuggestedPhrasesContainer>
                <SuggestedActionsTitle>Suggested Phrases:</SuggestedActionsTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(question.body.suggestedPhrases)
                  }}
                />
              </SuggestedPhrasesContainer>
            )}
            {!!question.body.resources?.length && (
              <ResourcesContainerWrapper>
                <ResourcesContainerComponent question={question} resourcesRef={resourcesRef} />
              </ResourcesContainerWrapper>
            )}
          </div>
        </ActionPlanInnerStepContainer>
      </ActionPlanStepContainer>
    </ActionPlanStepWrapper>
  )
}

export function ActionPlanStep({ preview = false, step, score }) {
  return (
    <RootContainer>
      <ActionPlanStepTitle>{step.title}</ActionPlanStepTitle>
      <ScoreContainerWrapper width={1 / 1}>
        <ScoreContainer>
          <CircularScore score={score} />
        </ScoreContainer>
      </ScoreContainerWrapper>
      {step.questions.map((question, questionIndex) => (
        <ActionPlanBody question={question} questionIndex={questionIndex} preview={preview} score={score} />
      ))}
    </RootContainer>
  )
}

function ResourcesContainerComponent({ question, resourcesRef }) {
  return (
    <ResourcesContainer ref={resourcesRef}>
      <ResourcesContainerTitle>Resources</ResourcesContainerTitle>
      <Text fontSize="14px" fontWeight={400} lineHeight="20px" mt="12px" color="#00000099">
        Use the list below to discover materials that could be helpful on this topic.
      </Text>
      <Flex width={1 / 1} flexWrap="wrap">
        {question.body.resources.map((resource, index) => {
          const title =
            resource.mediaType === "article"
              ? "Article"
              : resource.mediaType === "book"
              ? "Book"
              : resource.mediaType
          const resourceUrl = resource.url.replace(/\s/g, "")

          return (
            <Flex key={index} width={1 / 3} p={2} flexDirection="column">
              <Text fontSize="14px" fontWeight="700" lineHeight="20px" color="#00000099" width={1 / 1}>
                {title} -{" "}
                {resourceUrl ? (
                  <a href={resourceUrl} target="_blank" rel="noopener noreferrer">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        textAlign: "left",
                        textDecoration: "underline",
                        color: "#003E4C"
                      }}
                    >
                      {resource.title}
                    </span>
                  </a>
                ) : (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#003E4C"
                    }}
                  >
                    {resource.title}
                  </span>
                )}
              </Text>
              {resource.description ? <Text width={1 / 1}>{resource.description}</Text> : null}
            </Flex>
          )
        })}
      </Flex>
    </ResourcesContainer>
  )
}

const QuestionTitle = styled.h3`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  color: #00000099;
`
const QuestionAnswer = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #00000099;

  margin: 0;
`

export function ActionStepPreviewQuestions({ questions }) {
  return (
    <OrderedList>
      {questions.map((item, subQuestionIndex) => (
        <OrderedListItem key={subQuestionIndex}>
          <QuestionTitle>{item.title}</QuestionTitle>
          <UnorderedList>
            {item.answers.map((answer, answerIndex) => (
              <li key={answerIndex}>
                <QuestionAnswer>{answer}</QuestionAnswer>
              </li>
            ))}
          </UnorderedList>
        </OrderedListItem>
      ))}
    </OrderedList>
  )
}
