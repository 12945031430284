import { Box, Flex, MobileOnly, TabletUp, Text } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { isQuestionVisible } from "../../../utils/logic"
import SurveyIcon from "../../ui/components/SurveyIcon/SurveyIcon"
import questionContainer from "../containers/question.container"
import { useSectionContext } from "../contexts/sectionContext"
import Answer from "./answer/Answer"
import ProgressBar from "./progressBar"
import SurveyCard from "./surveyCard"

const Question = ({
  answers,
  data,

  isFirstQuestion,
  question,
  previousAnswer,

  onAnswer,
  onRemoveAnswer,

  sectionTitle,
  isLastQuestion,
  onNext,
  onPrevious
}) => {
  const { t } = useTranslation(["organisation_survey"])

  const { branchingLogics, currentQuestion, currentSection, sectionsList } = useSectionContext()

  const [visible, setVisible] = useState(false)
  const [answer, setAnswer] = useState(previousAnswer)

  const questionBranchingLogics = branchingLogics.find(i => i.targetQuestion === question.id)
  const { type, id, title, required, description, icon, min, max, fields, values, label } = question

  const isLastSection = currentSection === sectionsList.length - 1

  const clearSkippedAnswer = () => {
    const foundAnswer = answers?.filter(a => a.questionId === id)

    if (foundAnswer.length) onRemoveAnswer(id)

    return null
  }

  useEffect(() => {
    const show = isQuestionVisible(id, answers, data?.template.questionLogics, setAnswer)

    if (show) setVisible(true)
    else {
      clearSkippedAnswer()
      onNext(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, id, onNext, question, data.template.questionLogics])

  const handleAnswer = value => {
    setAnswer({
      id,
      value: [value]
    })
  }

  const handleSubmit = () => {
    if (answer) onAnswer(answer)

    const foundAnswer = answers?.filter(a => a.questionId === id)

    if (foundAnswer && foundAnswer.length > 0 && foundAnswer[0].value[0] !== answer.value[0]) {
      const sectionData = sectionsList[currentSection]

      onRemoveAnswer(sectionData.questions[currentQuestion + 1])
    }

    const branchingLogic = data?.template?.branchingLogic.find(
      i => i.currentQuestion === question.id && i.choice === answer.value[0]
    )

    onNext(branchingLogic)
  }

  const handlePreviousQuestion = () => {
    if (questionBranchingLogics) onRemoveAnswer(questionBranchingLogics.currentQuestion)
    onPrevious(question.id)
  }

  const validChecker = () => {
    if (!required) return true

    if (!answer || !answer?.id) return false

    switch (type) {
      case "scale":
        return answer.value?.every(value => typeof value === "number")
      case "short-text": {
        const validFields = answer.value?.[0]?.filter(input => !!input)
        return !!validFields.length
      }
      default:
        return answer.value?.every(value => value)
    }
  }

  if (!visible) return null

  return (
    <SurveyCard
      isLastQuestion={isLastQuestion}
      title={sectionTitle}
      onNext={handleSubmit}
      onPrevious={handlePreviousQuestion}
      type={type}
      answer={answer}
      isValid={validChecker()}
      isFirstQuestion={isFirstQuestion}
      isLastSection={isLastSection}
    >
      <Flex>
        <TabletUp>
          <SurveyIcon name={icon} mr={3} />
        </TabletUp>
        <Box>
          <MobileOnly>
            <Flex mb={3} alignItems="flex-end">
              <SurveyIcon name={icon} mr={3} />
              <ProgressBar id={id} />
            </Flex>
          </MobileOnly>

          <TabletUp>
            <ProgressBar id={id} />
          </TabletUp>

          <Text fontWeight="500" color="dark.2" fontSize={[3, 4]}>
            {title}
          </Text>

          {!required && (
            <Text fontSize={3} color="dark.2">
              {" "}
              ({t("questions.optional", "")})
            </Text>
          )}

          <Answer
            type={type}
            fields={fields}
            values={
              questionBranchingLogics
                ? values?.filter(i => questionBranchingLogics.targetAnswers.includes(i.id))
                : values
            }
            description={description}
            min={min}
            max={max}
            label={label}
            onAnswer={value => handleAnswer(value)}
            initialValue={answer}
            allAnswers={answers}
            choiceLogics={data.template.choiceLogics}
          />
        </Box>
      </Flex>
    </SurveyCard>
  )
}

export default questionContainer(Question)
